<template>
  <el-dialog
    v-model="showMatchDetailDialog"
    width="30%"
    align-center
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    class="elv-match-detail-dialog"
    @close="onCloseDialog"
  >
    <template #header="{ titleId }">
      <h4 :id="titleId" class="elv-match-detail-dialog-header__title">
        {{ t('report.matchDetail') }}
      </h4>
    </template>
    <div v-loading="loading" class="elv-match-detail-dialog-container">
      <div class="elv-match-detail-dialog-content-header">
        <div class="elv-match-detail-dialog-content-header__info">
          <h5>{{ reconciliationMatchDetail?.matchNo }}</h5>
          <p>
            {{
              reconciliationMatchDetail?.user?.name
                ? `Manual matched by ${reconciliationMatchDetail?.user?.name}`
                : `Matched by rule: ${reconciliationMatchDetail?.reconciliationRule?.name}`
            }}
            <span>{{
              dayjs(reconciliationMatchDetail?.datetime)
                .tz(entityStore.entityDetail?.timezone ?? 'UTC')
                .format('YYYY/MM/DD HH:mm:ss')
            }}</span>
          </p>
        </div>
        <div class="elv-match-detail-dialog-content-header__operating">
          <div class="elv-match-detail-button-unmatched" @click="onUnmatchedReconciliation">
            <SvgIcon name="unlink" width="14" height="14" />{{ t('report.unmatch') }}
          </div>
        </div>
      </div>
      <div class="elv-match-detail-dialog-content">
        <div class="elv-match-detail-dialog-content-left">
          <h6>{{ t('report.Transactions') }}</h6>
          <div
            v-for="(item, index) in reconciliationMatchDetail.transactionList"
            :key="index"
            class="elv-match-detail-dialog-content-item"
          >
            <div class="elv-match-detail-dialog-content-item__info">
              <p>
                {{
                  dayjs(item.datetime)
                    .tz(entityStore.entityDetail?.timezone ?? 'UTC')
                    .format('YYYY/MM/DD HH:mm')
                }}<span v-if="item.txHash">{{ middleEllipsis(item.txHash) }}</span>
              </p>
              <FromToCell :params="{ data: item }" />
            </div>
            <div class="elv-match-detail-dialog-content-item__balance">
              <div class="elv-match-detail-dialog-content-item__balance-type">
                {{ transformI18n(transactionTypeData(item.type).value) }}
              </div>
              <div class="elv-match-detail-dialog-content-item__balance-value">
                {{ formatNumberToSignificantDigits(item.amount ?? 0, 2, '', false) }}
                {{ item.underlyingCurrency?.showSymbol }}
              </div>
            </div>
          </div>
        </div>
        <div class="elv-match-detail-dialog-content-right">
          <h6>{{ t('common.ledger') }}</h6>
          <div
            v-for="(item, index) in reconciliationMatchDetail.ledgerList"
            :key="index"
            class="elv-match-detail-dialog-content-item"
          >
            <div class="elv-match-detail-dialog-content-item__info">
              <p>
                {{ dayjs.utc(item?.datetime).format('YYYY/MM/DD') }}<span>{{ item?.journal?.journalNo }}</span>
              </p>
              <div class="elv-match-detail-dialog-content-item__title">{{ item?.chartOfAccount?.name }}</div>
            </div>
            <div class="elv-match-detail-dialog-content-item__balance">
              <div class="elv-match-detail-dialog-content-item__balance-type">
                {{ item.creditFC !== null && item.creditFC !== '0' && item.creditFC !== 0 ? 'Cr' : 'Dr' }}
              </div>
              <div class="elv-match-detail-dialog-content-item__balance-value">
                {{
                  formatNumberToSignificantDigits(
                    item.credit !== null && item.credit !== '0' && item.credit !== 0 ? item.credit : item.debit,
                    2,
                    '',
                    false
                  )
                }}
                {{ item.currency.showSymbol }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { flatMap } from 'lodash-es'
import { ElMessage } from 'element-plus'
import { transformI18n } from '@/i18n/index'
import timezone from 'dayjs/plugin/timezone'
import { transactionType } from '@/config/index'
import ReconciliationApi from '@/api/ReconciliationApi'
import { useEntityStore } from '@/stores/modules/entity'
import { useComputedHook } from '@/hooks/useComputedHook'
import FromToCell from '@/components/Reports/Cell/FromToCell.vue'
import { middleEllipsis, formatNumberToSignificantDigits } from '@/lib/utils'

const props = defineProps({
  currentData: {
    type: Object,
    default: () => {
      return {}
    }
  },
  reconciliationMatchId: {
    type: String,
    required: true
  }
})

dayjs.extend(utc)
dayjs.extend(timezone)
const { t } = useI18n()
const route = useRoute()
const entityStore = useEntityStore()
const loading = ref(false)
const reconciliationMatchDetail: any = ref({})
const emit = defineEmits(['onResetList'])

const entityId = computed(() => {
  return String(route.params?.entityId as string)
})

const transactionTypeData: any = useComputedHook((type: string) => {
  let data = ''
  const transactionTypeList = flatMap(transactionType, 'list')
  transactionTypeList.forEach((item) => {
    if (item.value === type) {
      data = item.label
    }
  })
  return data
})

const showMatchDetailDialog = ref(false)

const onUnmatchedReconciliation = async () => {
  try {
    loading.value = true
    await ReconciliationApi.batchReconciliationUnmatched(
      entityId.value,
      props.currentData?.reconciliationSetId || reconciliationMatchDetail.value?.reconciliationSetId,
      {
        reconciliationMatchIds: [props.reconciliationMatchId]
      }
    )
    emit('onResetList')
    ElMessage.success(t('message.success'))
    showMatchDetailDialog.value = false
  } catch (error: any) {
    ElMessage.error(error.message)
  } finally {
    loading.value = false
  }
}

const onCheckMatchDetailDialog = () => {
  showMatchDetailDialog.value = !showMatchDetailDialog.value
}

const onCloseDialog = () => {
  console.log(222)
}

defineExpose({ onCheckMatchDetailDialog })

watch([() => showMatchDetailDialog.value, () => props.reconciliationMatchId], async () => {
  if (showMatchDetailDialog.value && props.reconciliationMatchId !== '') {
    try {
      loading.value = true
      const { data } = await ReconciliationApi.getReconciliationMatchDetail(entityId.value, props.reconciliationMatchId)
      reconciliationMatchDetail.value = data
    } catch (error: any) {
      ElMessage.error(error.message)
    } finally {
      loading.value = false
    }
  }
})
</script>

<style lang="scss">
.elv-match-detail-dialog {
  width: 980px;
  box-shadow:
    0px 2px 6px rgba(0, 0, 0, 0.05),
    0px 0px 1px rgba(0, 0, 0, 0.3);
  border-radius: 6px;

  .el-dialog__header {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    height: 54px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 700;
    margin: 0;
    padding: 0;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-start;
    color: #0e0f11;
    padding-left: 24px;
    border-bottom: 1px solid #edf0f3;

    .elv-match-detail-dialog-header__title {
      font-family: 'Plus Jakarta Sans';
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #0e0f11;
      margin: 0;
    }
  }

  .el-dialog__body {
    padding: 18px 24px 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    .elv-match-detail-dialog-container {
      width: 100%;
    }

    .elv-match-detail-dialog-content-header {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      padding-bottom: 10px;
      border-bottom: 1px solid #edf0f3;
      margin-bottom: 16px;
      width: 100%;

      .elv-match-detail-dialog-content-header__info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        h5 {
          font-family: 'Plus Jakarta Sans';
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          color: #0e0f11;
        }

        p {
          font-family: 'Plus Jakarta Sans';
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          margin-top: 8px;

          span {
            color: #aaafb6;
            font-weight: 400;
            font-size: 12px;
            line-height: 24px;
            margin-left: 4px;
          }
        }
      }

      .elv-match-detail-dialog-content-header__operating {
        display: flex;
        align-items: flex-end;
      }

      .elv-match-detail-button-unmatched {
        display: flex;
        height: 30px;
        padding: 0px 12px;
        box-sizing: border-box;
        justify-content: center;
        align-items: center;
        border-radius: 22px;
        background: #e5edff;
        cursor: pointer;
        color: #1e2024;
        font-family: 'Plus Jakarta Sans';
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        svg {
          margin-right: 4px;
          fill: #1e2024;
        }
      }
    }

    .elv-match-detail-dialog-content {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 12px;
    }

    .elv-match-detail-dialog-content-left,
    .elv-match-detail-dialog-content-right {
      width: 458px;

      h6 {
        color: #000;
        font-family: 'Plus Jakarta Sans';
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 16px;
      }

      .elv-match-detail-dialog-content-item {
        width: 100%;
        box-sizing: border-box;
        padding: 8px;
        height: 57px;
        border-radius: 3px;
        border: 1px solid #dde1e6;
        background: #f9fafb;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 8px;

        &:last-of-type {
          margin-bottom: 0px;
        }

        .elv-match-detail-dialog-content-item__info {
          p {
            display: flex;
            align-items: center;
            color: #636b75;
            font-family: 'Barlow';
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-bottom: 4px;

            span {
              display: inline-block;
              font-size: 11px;
              margin-left: 8px;
              padding-left: 8px;
              border-left: 1px solid #dde1e6;
            }
          }

          .elv-match-detail-dialog-content-item__title {
            color: #1e2024;
            font-family: 'Plus Jakarta Sans';
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 23px;
          }
        }

        .elv-match-detail-dialog-content-item__balance {
          display: flex;
          align-items: flex-end;
          flex-direction: column;

          .elv-match-detail-dialog-content-item__balance-type {
            color: #1e2024;
            font-family: 'Barlow';
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-bottom: 4px;
          }

          .elv-match-detail-dialog-content-item__balance-value {
            color: #1e2024;
            font-family: 'Barlow';
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }
        }
      }
    }
  }

  .el-dialog__footer {
    padding: 20px 0px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
